import '../Style/Header.css'

const Header = () =>{
    return (
        <div className="header">
            <h1>Todo List</h1>
        </div>
        );
}

export default Header;